<template>
  <div
    class="dropdown-container position-static no-focus d-inline-flex"
    :class="containerClass"
  >
    <b-dropdown
      block
      id="currency-dropdown"
      :menu-class="{
        'dropdown-menu-scroll flex-1': haveValues,
        'no-data-menu-class': !haveValues
      }"
      variant="outline-primary"
      lazy
    >
      <template #button-content>
        <currency-item
          v-if="selectedCurrency"
          :currency="selectedCurrency"
        ></currency-item>
        <span v-else>{{ $t("FORM.SELECT_A_CURRENCY_UNIT") }}</span>
      </template>
      <template v-if="haveValues">
        <b-dropdown-item-button
          v-for="currency in currencies"
          :key="currency.id"
          @click="$emit('currency-selected', currency)"
        >
          <currency-item :currency="currency"></currency-item>
        </b-dropdown-item-button>
      </template>
      <b-dropdown-text v-else>
        {{ this.$t("COMMON.NO_DATA_AVAILABLE") }}
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import CurrencyItem from "./CurrencyItem.vue";

export default {
  components: { CurrencyItem },
  props: {
    currencies: [],
    selectedCurrency: {},
    containerClass: {
      type: String,
      default: "col-12 col-md-6 col-md"
    }
  },
  computed: {
    haveValues() {
      return this.currencies && this.currencies.length > 0;
    }
  }
};
</script>

<style lang="scss">
$border-color: 1px solid #ebedf3;

#currency-dropdown {
  position: initial;
  flex: 1;
}

.no-focus {
  .btn.btn-outline-primary:focus:not(.btn-text),
  .btn.btn-outline-primary.focus:not(.btn-text) {
    color: #3699ff;
    background-color: transparent;
    border-color: #3699ff;
  }
}

.dropdown-menu-scroll {
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
  z-index: 150;
  // height: 70vh;
  overflow-y: scroll;
  padding-inline-start: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  box-sizing: border-box;

  &.show {
    display: flex;
    // transform: translate3d(0, 0, 0) !important;
  }

  > li {
    @media (min-width: 768px) {
      width: 25%;
    }
    width: 50%;
    border-bottom: $border-color;
    border-right: $border-color;
  }
}
.no-data-menu-class {
  width: 40% !important;
  background: #e4e6ef;
}
@media (max-width: 768px) {
  .no-data-menu-class {
    width: 75% !important;
    background: #e4e6ef;
  }
}
</style>
